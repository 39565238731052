@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700;800&family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,400;0,500;0,700;0,800;1,400;1,800&family=Roboto+Mono:ital,wght@0,100;0,400;0,500;0,700;1,400;1,700&family=Ubuntu:wght@400;700&display=swap");

* {
  font-family: "Inter", sans-serif;
  font-family: "Lato", sans-serif;
  font-family: "Open Sans", sans-serif;
  font-family: "Roboto Mono", monospace;
  font-family: "Ubuntu", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.gallery > .gallery-img {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  transition: all 0.3s ease;
  cursor: pointer;
}

.gallery:has(.gallery-img) .gallery-img:not(:hover) {
  scale: 0.9;
  opacity: 0.9;
  /* filter: grayscale(70%); */
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
}

.bestsellerdiv:hover .overlay {
  opacity: 1;
}

.text {
  color: #222;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: transparent;
  width: 100%;
}

.bestsellerdiv:hover .image {
  opacity: 0.5;
  transition: 0.3s ease-in-out;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .tbl-row div:nth-child(even) {
    @apply bg-gray-100;
  }
}
