.calendar-view {
    padding:0px  50px 60px 50px;
}

.fc-dayGridMonth-view{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
}


.fc-button{
    background-color: #8a5cf6ae !important;
    border-color: #8b5cf6 !important;
    padding: 5px 50px !important ;
}

.fc-dayGridMonth-view{
    border-top: 1px solid #8a5cf6ae !important;
    border-bottom: 1px solid #8a5cf6ae !important;
}
